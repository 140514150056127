import { render } from "./GoAhpView.vue?vue&type=template&id=63c274c8"
import script from "./GoAhpView.vue?vue&type=script&lang=ts"
export * from "./GoAhpView.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "63c274c8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('63c274c8', script)) {
    api.reload('63c274c8', script)
  }
  
  module.hot.accept("./GoAhpView.vue?vue&type=template&id=63c274c8", () => {
    api.rerender('63c274c8', render)
  })

}

script.__file = "src/views/GoAhpView.vue"

export default script