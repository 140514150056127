
import { defineComponent } from 'vue'
import { NavigationGuard } from 'vue-router'

import { ROUTE_NAME } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { fixEncodedQueryString } from '@/libs/utils'
import { useStore } from '@/store'
import { AuthActionEnum } from '@/store/enums/actions/auth'
import { vuexActions } from '@/store/util'

const loadPage: NavigationGuard = async (to, from, next) => {
  const store = useStore()
  const { workspaceId } = to.params
  const { surveyKey } = to.query

  if (surveyKey && typeof surveyKey === 'string') {
    const fixedSurveyKey = fixEncodedQueryString(surveyKey)
    const decodedSurveyKey = decodeURIComponent(fixedSurveyKey)

    await store.dispatch(
      vuexActions(ModuleNames.AUTH, AuthActionEnum.AUTH_AHP_SURVEY_KEY_LOGIN),
      decodedSurveyKey
    )
    try {
      next({
        name: ROUTE_NAME.AHP,
        params: {
          workspaceId
        }
      })
    } catch (err) {
      console.log(err)
    }
    return
  }
}

export default defineComponent({
  beforeRouteEnter: loadPage as NavigationGuard
})
